<template>
  <b-tab
    title="Dados Gerais"
    @click="() => (store.currentTab = 0)"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
  >
    <b-row>
      <b-col sm="2" v-if="store.trabalharNumeroInternoOs">
        <b-form-group label="Número Interno" label-for="numero_interno">
          <b-form-input
            v-model="form.numero_interno"
            autocomplete="off"
            maxLength="10"
          />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Cliente" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            :state="validateState('cliente')"
            v-model="form.cliente"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clientes"
            @search="onSearchCliente"
            :clearSearchOnSelect="true"
          >
            <template slot="no-options"> Informe o nome do cliente </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.nome }}</div>
                <span class="text-muted"
                  >CPF: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Técnico" label-for="tecnico">
          <VueSelect
            ref="buscarTecnico"
            :filterable="false"
            :state="validateState('tecnico')"
            v-model="form.tecnico"
            :class="{ hasErrors: tecnicoError, inputFilled: tecnicoFilled }"
            :options="tecnicos"
            @search="onSearchTecnico"
            :clearSearchOnSelect="true"
          >
            <template slot="no-options"> Informe o nome do tecnico </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.nome }}
                </div>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Endereço" label-for="endereco">
          <VueSelect
            ref="endereco"
            :filterable="false"
            @input="setEndereco"
            :options="enderecos"
            :class="{ hasErrors: enderecoError, inputFilled: enderecoFilled }"
            @search="onSearchEndereco"
            :value="formatEndereco(form.cliente.endereco)"
          >
            <template slot="no-options"> Informe o Endereço </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{
                  `${option.cep ?? ""}, ${option.logradouro ?? ""}, ${
                    option.numero ?? ""
                  }, ${option.bairro ?? ""} - ${
                    option?.cidade?.cidade ?? ""
                  } / ${option?.cidade?.uf ?? ""}`
                }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="2">
        <b-form-group
          id="dataEntrada"
          label-for="dataEntrada"
          label="Data Entrada"
        >
          <b-form-datepicker
            v-model="form.dataEntrada"
            :state="validateState('dataEntrada')"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          id="dataPrevisao"
          label-for="dataPrevisao"
          label="Data Previsão"
        >
          <b-form-datepicker
            v-model="form.dataPrevisao"
            :state="validateState('dataPrevisao')"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group id="dataSaida" label-for="dataSaida" label="Data Saída">
          <b-form-datepicker
            v-model="form.dataSaida"
            :state="validateState('dataSaida')"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          id="proxRevisao"
          label-for="proxRevisao"
          label="Próxima Revisão"
        >
          <b-form-datepicker
            v-model="form.proxRevisao"
            :state="validateState('proxRevisao')"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          id="dataConclusao"
          label-for="dataConclusao"
          label="Data Conclusão"
        >
          <b-form-datepicker
            v-model="form.dataConclusao"
            :state="validateState('dataConclusao')"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label="Garantia" label-for="garantia">
          <b-form-input
            v-model="form.garantia"
            autocomplete="off"
            :maxLength="50"
          />
        </b-form-group>
      </b-col>
      <b-col sm="5">
        <b-form-group label="Solicitante" label-for="solicitante">
          <b-form-input
            v-model="form.solicitante"
            autocomplete="off"
            :maxLength="50"
          />
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Hora Agendamento" label-for="horaAgendamento">
          <b-form-input v-model="form.horaAgendamento" type="time" />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Local do Atendimento" label-for="local_atendimento">
          <b-form-input
            v-model="form.local_atendimento"
            autocomplete="off"
            maxLength="100"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4" style="margin-top: 35px" v-if="form.cliente.tipo_atacado">
        <span class="label label-lg label-inline label-light-warning"
          >Tipo Atacado</span
        >
      </b-col>
      <b-col sm="4" v-if="listaPreco">
        <p class="text-muted">
          Lista de preço aplicada:
          <span class="font-weight-bold text-primary">{{
            listaPreco.descricao
          }}</span>
        </p>
      </b-col>
    </b-row>

    <b-modal
      id="confirmObjectModal"
      centered
      title="Vários Objetos para um mesmo cliente"
    >
      <b-row>
        <b-col>
          <p>
            Este cliente possui vários objetos cadastrados. Por favor, selecione
            o objeto desejado abaixo.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <VueSelect
              ref="buscarObjeto"
              :filterable="false"
              :searchable="false"
              v-model="objetoSelected"
              :options="objetosFetched"
              :clearSearchOnSelect="true"
            >
              <template slot="no-options"> Informe o nome do objeto </template>
              <template slot="option" slot-scope="option">
                <div class="d-flex justify-content-between">
                  <div>
                    {{ option.descricao }}
                  </div>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="objetoSelected?.identificadores?.length > 0">
        <b-col
          v-for="identificador in objetoSelected.identificadores"
          :key="identificador.id_identificador"
          sm="4"
        >
          <b-form-group
            :label="identificador.descricao"
            :label-for="identificador.descricao"
          >
            <b-form-input :value="identificador.value" disabled />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
              openModal = false;
            }
          "
        >
          Cancelar
        </b-button>
        <b-button variant="primary" ref="confirmObject" @click="confirmObject">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../../Application/mixins/FormatMixin";
import GeraisData, { NFeDataRequired } from "../GeraisData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../../Pdv/mixins/PagamentosMixin";
import { store } from "../../storeOS";
import InstallmentsMixin from "../../../Application/mixins/InstallmentsMixin";
import EmitenteMixin from "../../../Application/mixins/EmitenteMixin";

export default {
  name: "app-os-form-geraisTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {},
      clientes: [],
      enderecos: [],
      tecnicos: [],
      objetosFetched: [],
      objetoSelected: null,
      clienteError: false,
      clienteFilled: false,
      enderecoError: false,
      tecnicoError: false,
      enderecoFilled: false,
      tecnicoFilled: false,
      store,
      listaPreco: null,
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
    EmitenteMixin,
  ],
  validations: {
    form: NFeDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    formatKeyDevolution(e) {
      return String(e).substring(0, 44);
    },
    confirmObject() {
      this.store.formObjeto = {
        ...this.objetoSelected,
        fromSetClient: true,
      };
      this.objetoSelected = null;
      this.$bvModal.hide("confirmObjectModal");
    },
    async setCliente({ id_pessoa }) {
      const response = await this.$http.post("/objeto/get-objeto", {
        pessoa: id_pessoa,
      });
      this.objetosFetched = response.data;

      if (this.objetosFetched.length > 0 && this.objetosFetched.length === 1)
        this.store.formObjeto = {
          ...this.objetosFetched[0],
          fromSetClient: true,
        };
      else if (this.objetosFetched.length > 1) {
        this.$bvModal.show("confirmObjectModal");
      }

      if (this.form.cliente) {
        this.enderecos = this.form.cliente.enderecos;
        this.form.cliente.endereco = this.form.cliente.enderecos[0];
      }

      this.listaPreco = null;

      if (this.form.cliente?.lista_preco) {
        this.listaPreco = this.form.cliente?.lista_preco;
      }
    },
    async getDataInit() {
      this.store.currentTab = 0;
      this.form = new GeraisData({});
      this.form.dataEntrada = this.thisDate();
      this.form.horaAgendamento = this.thisTime();

      if (Object.keys(this.$route.params).length !== 0) {
        this.handleLoading(true);
        const {
          cliente,
          tecnico,
          dataEntrada,
          dataPrevisao,
          dataSaida,
          proximaRevisao,
          garantia,
          endereco,
          solicitante,
          horaAgendamento,
          dataConclusao,
          local_atendimento,
          numero_interno,
        } = this.$route.params.osData;
        this.form.cliente = cliente;
        if (endereco) {
          this.form.cliente.endereco = endereco;
        }
        this.form.tecnico = tecnico;
        this.form.dataEntrada = dataEntrada;
        this.form.dataPrevisao = dataPrevisao;
        this.form.dataSaida = dataSaida;
        this.form.proxRevisao = proximaRevisao;
        this.form.garantia = garantia;
        this.form.solicitante = solicitante;
        this.form.horaAgendamento = horaAgendamento;
        this.form.dataConclusao = dataConclusao;
        this.form.local_atendimento = local_atendimento;
        this.form.numero_interno = numero_interno;
        this.handleLoading(false);
      } else {
        console.log("");
      }
    },
    handleLoading(value) {
      this.setLoading(value);
    },

    onSearchCliente(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = [];
      await vm.$nextTick();
      const data = await vm.getClientes(search);
      vm.clientes = _.cloneDeep(data);
      loading(false);
    }, 500),

    onSearchTecnico(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchTecnico(loading, search, this);
      }
    },
    searchTecnico: _.debounce(async (loading, search, vm) => {
      vm.tecnicos = [];
      await vm.$nextTick();
      const data = await vm.getTecnicos(search);
      vm.tecnicos = _.cloneDeep(data);
      loading(false);
    }, 500),

    cleanErrors() {
      this.naturezaError = false;
      this.finalidadeError = false;
    },
    validateState(name) {
      // const { $dirty, $error } = this.$v.form[name];
      // return $dirty ? !$error : null;
    },
    searchEndereco: _.debounce(async (loading, search, vm) => {
      vm.enderecos = vm.enderecos.filter((endereco) =>
        endereco.includes(search)
      );
      loading(false);
    }, 350),
    onSearchEndereco(search, loading) {
      if (search.length) {
        loading(true);
        this.searcEndereco(loading, search, this);
      }
    },
    setEndereco(endereco) {
      this.form.cliente.endereco = endereco;
      this.enderecoFilled = true;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.complemento ?? ""}, ${
        endereco.bairro ?? ""
      } - ${endereco?.cidade?.cidade ?? ""} / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
  },
  mounted() {
    this.getDataInit();
  },
  watch: {
    form: {
      handler(newValue) {
        this.store.formGerais = newValue;
      },
      deep: true,
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        this.$v.form.$touch();
        if (_.isEmpty(this.form.cliente)) {
          this.$toast.error("Cliente Obrigatório!");
          this.store.currentTab = oldValue;
          return;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isDevolution() {
      return this.form?.finalidade?.descricao === "Devolução de mercadoria";
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
  },
};
</script>

<style>
.hasErrors .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.hasErrors .vs__clear,
.hasErrors .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1bc5bd;
}
</style>