<template>
  <b-tab
    title="Anexos"
    :active="isTheCurrentTab"
    @click="() => (store.currentTab = 7)"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
  >
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 1" label-for="anexo1">
          <a :href="anexo_1" class="rounded-0" v-if="anexo_1" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_1')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 2" label-for="anexo2">
          <a :href="anexo_2" class="rounded-0" v-if="anexo_2" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_2')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 3" label-for="anexo3">
          <a :href="anexo_3" class="rounded-0" v-if="anexo_3" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_3')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 4" label-for="anexo4">
          <a :href="anexo_4" class="rounded-0" v-if="anexo_4" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_4')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 5" label-for="anexo5">
          <a :href="anexo_5" class="rounded-0" v-if="anexo_5" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_5')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Anexo 6" label-for="anexo6">
          <a :href="anexo_6" class="rounded-0" v-if="anexo_6" target="_blank"
            >Visualizar Anexo</a
          >
          <b-form-file
            accept="image/jpeg, image/png, application/pdf"
            @change="(event) => setAnexo(event, 'anexo_6')"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { store } from "../../storeOS";

export default {
  name: "app-os-form-anexosTab",
  data() {
    return {
      store,
      anexo_1: null,
      anexo_2: null,
      anexo_3: null,
      anexo_4: null,
      anexo_5: null,
      anexo_6: null,
      form: {
        anexo_1: null,
        anexo_2: null,
        anexo_3: null,
        anexo_4: null,
        anexo_5: null,
        anexo_6: null,
      },
      tabCurrent: 7,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async setAnexo(event, anexo) {
      const data = await event.target.files[0];
      const url = URL.createObjectURL(data);
      this[anexo] = url;
      this.form[anexo] = data;
    },
  },
  watch: {
    form: {
      handler(value) {
        this.store.formAnexos = value;
      },
      deep: true,
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { anexo_1, anexo_2, anexo_3, anexo_4, anexo_5, anexo_6 } =
        this.$route.params.osData;

      this.anexo_1 = anexo_1;
      this.form.anexo_1 = anexo_1;
      this.anexo_2 = anexo_2;
      this.form.anexo_2 = anexo_2;
      this.anexo_3 = anexo_3;
      this.form.anexo_3 = anexo_3;
      this.anexo_4 = anexo_4;
      this.form.anexo_4 = anexo_4;
      this.anexo_5 = anexo_5;
      this.form.anexo_5 = anexo_5;
      this.anexo_6 = anexo_6;
      this.form.anexo_6 = anexo_6;
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      if (store.trabalharHorasOs) {
        this.tabCurrent == 8;
      }
      return store.currentTab === this.tabCurrent;
    },
  },
};
</script>