<template>
  <b-tab
    title="Serviços (Opcional)"
    @click="() => (store.currentTab = 4)"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
  >
    <b-row align-v="center">
      <b-col md="10">
        <b-form-group label="Serviço" label-for="servico">
          <VueSelect
            ref="buscarServico"
            :filterable="false"
            @input="setServico"
            :options="servicosFetched"
            @search="onSearchServico"
            v-model="servicoSelected"
          >
            <template slot="no-options"> Informe o serviço pelo nome </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.discriminacao }}
                </div>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.discriminacao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="quantidade"
            v-bind="quantityOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Unitário" label-for="unitario">
          <money
            class="form-control"
            id="unitario"
            name="unitario"
            v-model="unitario"
            v-bind="priceOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Total R$" label-for="total">
          <money
            disabled
            class="form-control"
            id="total"
            name="total"
            v-model="total"
            v-bind="priceOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="add">
          <button @click="addServico(servicoSelected)">
            <Plus
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9"
            />
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableApp
          item="serviço"
          subTitulo=""
          :data="servicos"
          :fields="fields"
          classFilterColumn="nome"
          ref="nome"
          :key="keyToRender"
        >
          <template slot="customRow" scope="props">
            <span
              slot="discriminacao"
              class="pl-0 pt-8"
              v-if="props.field == 'discriminacao'"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.discriminacao }}
                </div>
              </div>
            </span>
            <span
              slot="quantidade"
              class="pl-0 pt-8"
              v-if="props.field == 'quantidade'"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.quantidade) }}
                </div>
              </div>
            </span>
            <span
              slot="unitario"
              class="pl-0 pt-8"
              v-if="props.field == 'unitario'"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.unitario) }}
                </div>
              </div>
            </span>
            <span
              disabled
              slot="total"
              v-if="props.field == 'total'"
              class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              R$
              {{ numberToReal(props.rowData.total, 2) }}
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span
                  style="overflow: visible; position: relative; width: 130px"
                >
                  <div class="action-buttons">
                    <button
                      @click="
                        openModalServico(
                          props.rowData,
                          props.rowData.originalIndex
                        )
                      "
                      class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                    >
                      <Pencil :size="16" title="Editar" />
                    </button>
                    <button
                      @click="handleDeleteServico(props.rowData)"
                      class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                    >
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>
        <b-row v-if="servicos.length > 0">
          <b-col></b-col>
          <b-col></b-col>
          <b-col
            ><span class="text-primary font-weight-bolder"
              >Total Serviços:</span
            >
            {{
              servicos.reduce((acc, servico) => acc + servico.quantidade, 0)
            }}</b-col
          >
          <b-col
            ><span class="text-primary font-weight-bolder">Total R$:</span
            >{{
              numberToReal(
                servicos.reduce((acc, servico) => acc + servico.total, 0),
                "preco"
              )
            }}</b-col
          >
        </b-row>
        <ModalServico
          :close="closeModalServico"
          :changeServico="saveEditProduto"
          :servico="servicoToEdit"
          :open="modalServicoIsOpen"
          v-if="modalServicoIsOpen"
        />
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOS";
import TableApp from "@/components/Table/Table3.vue";
import { Money } from "v-money";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import { priceOptions, quantityOptions } from "@/modules/Application";
import ModalServico from "./ModalServico.vue";

export default {
  name: "app-nfse-form-servicos",
  components: {
    VueSelect,
    TableApp,
    Money,
    Plus,
    Pencil,
    TrashCanIcon,
    ModalServico,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      priceOptions,
      quantityOptions,
      form: {},
      servicoSelected: null,
      servicosFetched: [],
      servicos: [],
      quantidade: 0,
      unitario: 0,
      total: 0,
      servicoToEdit: null,
      modalServicoIsOpen: false,
      keyToRender: 0,
      fields: [
        {
          label: "Descrição",
          field: "discriminacao",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Unitario",
          field: "unitario",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Total",
          field: "total",
          slot: true,
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    openModalServico(servicoData, originalIndex) {
      this.servicoToEdit = {
        ...servicoData,
        originalIndex,
      };
      this.modalServicoIsOpen = true;
    },
    closeModalServico() {
      this.modalServicoIsOpen = false;
    },
    handleDeleteServico(serviceToDelete) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o serviço " + serviceToDelete.discriminacao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.servicos
              .filter(
                (servico) =>
                  servico.originalIndex !== serviceToDelete.originalIndex
              )
              .map((serviceToDelete, index) => ({
                ...serviceToDelete,
                originalIndex: index,
              }));
            this.servicos = [...aux];
            this.keyToRender++;
            this.$set(this.store.formPagamentos, "pagamentos", [[]]);
            this.store.formPagamentos.pagamentos = [];
          }
        });
    },
    saveEditProduto(newEditServico) {
      this.servicos[newEditServico.originalIndex] = { ...newEditServico };
      this.keyToRender++;
      this.closeModalServico();
    },
    onSearchServico(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchServicos(loading, search, this);
      }
    },
    searchServicos: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post("/servicos/filter", {
        discriminacao: search,
      });
      vm.servicosFetched = [...data];
      loading(false);
    }, 350),
    setServico() {
      this.unitario = parseFloat(this.servicoSelected.valor);
      this.quantidade = 1;
      this.total = this.unitario * this.quantidade;
    },
    addServico(servico) {
      if (_.isEmpty(servico)) return;

      this.servicos.push({
        ...servico,
        total: this.total,
        unitario: this.unitario,
        quantidade: this.quantidade,
        originalIndex: this.servicos.length,
      });

      this.$set(this.store.formPagamentos, "pagamentos", [[]]);
      this.store.formPagamentos.pagamentos = [];

      this.clearFields();
    },
    clearFields() {
      this.quantidade = 0;
      this.unitario = 0;
      this.total = 0;
      this.desconto = 0;
      this.servicoSelected = null;
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { servicos } = this.$route.params.osData;
      this.servicos = servicos ?? [];
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return this.store.currentTab === 4;
    },
  },
  watch: {
    servicos: {
      handler(newValue) {
        this.store.formServicos = newValue;
      },
      deep: true,
    },
    quantidade(value) {
      this.total = this.unitario * parseFloat(value);
    },
    unitario(value) {
      this.total = parseFloat(value) * this.quantidade;
    },
    form(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.store.formCabecalho = newValue;
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 5 && oldValue < newValue) {
        console.log();
      }
    },
  },
};
</script>
