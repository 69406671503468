<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Nova Tributação
        </span>
      </h3>
    </div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Identificação
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab1"
              href="#"
              role="tab1"
            >
              Tributação de PIS e CONFINS
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab2"
              href="#"
              role="tab1"
            >
              Retenções
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab2"
              href="#"
              role="tab1"
            >
              Regras de ISS por cidade
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col>
                <b-form-group
                  id="descricao"
                  label-for="descricao"
                  label="Descrição"
                >
                  <b-form-input v-model="form.descricao" autofocus />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="tributacao"
                  label-for="tributacao"
                  label="Tributação"
                >
                  <b-form-select
                    v-model="tributacao"
                    placeholder="Selecione o tipo de tributação"
                    :key="render"
                  >
                    <b-form-select-option
                      v-for="tipo in tributacaoTipos"
                      :key="tipo.descricao + 'tributacao'"
                      :value="tipo.codigo"
                    >
                      {{ tipo.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="exigibilidade"
                  label-for="exigibilidade"
                  label="Exigibilidade"
                >
                  <b-form-select
                    v-model="exigibilidade"
                    placeholder="Selecione a exigibilidade"
                    :key="render"
                  >
                    <b-form-select-option
                      v-for="tipo in exigibilidadeTipos"
                      :key="tipo.descricao + 'exigibilidade'"
                      :value="tipo.codigo"
                    >
                      {{ tipo.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col>
                <b-form-group
                  id="cst"
                  label-for="cst"
                  label="CST de PIS/COFFINS"
                >
                  <VueSelect
                    label="codigo"
                    @input="setCst"
                    :options="cstcofinsOptions"
                    :value="cstSelected"
                    placeholder="Digite o código para pesquisar"
                  >
                    <template slot="no-options">
                      Informe o código do CST Cofins
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="base_pis_cofins"
                  label-for="base_pis_cofins"
                  label="% BC PIS/COFINS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    id="base_pis_cofins"
                    name="base_pis_cofins"
                    v-model="form.base_pis_cofins"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="aliquota_pis"
                  label-for="aliquota_pis"
                  label="% Aliquota PIS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.aliquota_pis"
                    autofocus
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="aliquota_cofins"
                  label-for="aliquota_cofins"
                  label="% Aliquota COFINS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.aliquota_cofins"
                    autofocus
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col>
                <b-form-group
                  id="retencao_pis"
                  label-for="retencao_pis"
                  label="% PIS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_pis"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="retencao_cofins"
                  label-for="retencao_cofins"
                  label="% COFINS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_cofins"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="retencao_csll"
                  label-for="retencao_csll"
                  label="% CSLL"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_csll"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="retencao_inss"
                  label-for="retencao_inss"
                  label="% INSS"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_inss"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="retencao_irrf"
                  label-for="retencao_irrf"
                  label="% IRFF"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_irrf"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="retencao_cpp"
                  label-for="retencao_cpp"
                  label="% CPP"
                >
                  <money
                    class="form-control"
                    v-bind="configMask"
                    v-model="form.retencao_cpp"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <button
              v-b-modal.modal-iss-cidade
              class="btn btn-sm btn-primary btn-hover-secondary btn-icon mr-2 mb-2"
            >
              <PlusIcon :size="26" />
            </button>
            <TableApp
              item="registro"
              subTitulo=""
              :data="regras"
              :fields="fields"
              classFilterColumn="cidade"
              ref="codade"
            >
              <template slot="customRow" scope="props">
                <span
                  slot="nome"
                  class="pl-0 pt-8"
                  v-if="props.field == 'cidade'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData?.cidade }}
                    </div>
                  </div>
                </span>
                <span
                  slot="iss"
                  class="pl-0 pt-8"
                  v-if="props.field == 'aliquota_iss'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ numberToReal(props.rowData?.aliquota_iss, 4) }}
                    </div>
                  </div>
                </span>
                <span
                  slot="preco"
                  class="pl-0 pt-8"
                  v-if="props.field == 'retencao_iss'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ numberToReal(props.rowData?.retencao_iss, 4) }}
                    </div>
                  </div>
                </span>
                <span slot="actions" v-if="props.field == 'actions'">
                  <div class="row-actions" style="text-align: right !important">
                    <span
                      style="
                        overflow: visible;
                        position: relative;
                        width: 130px;
                      "
                    >
                      <div class="action-buttons">
                        <button
                          @click="handleDeleteIss(props.rowData)"
                          class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                        >
                          <TrashCanIcon :size="16" />
                        </button>
                      </div>
                    </span>
                  </div>
                </span>
              </template>
            </TableApp>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_nova_tributacao"
              @click="onSubmit"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'tributacoesServicos',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-iss-cidade"
      size="md"
      centered
      title="Adicionar município"
    >
      <template #modal-header="{ close }">
        <h5>Adicionar município</h5>
        <b-button
          size="sm"
          variant="outline-danger"
          @click="
            () => {
              close();
              openModal = false;
            }
          "
        >
          X
        </b-button>
      </template>
      <div class="mx-auto d-flex flex-column justify-content-center">
        <b-row>
          <b-col>
            <b-form-group
              id="municipio"
              label-for="municipio"
              label="Município"
            >
              <VueSelect
                ref="municipioSelect"
                label="municipio"
                v-model="municipioSelected"
                :options="municipiosOptions"
                :filterable="false"
                placeholder="Pesquise pelo nome do município"
                @search="onSearchMunicipio"
              >
                <template slot="no-options">
                  Insira o nome do município
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.cidade + " - " + option.uf }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.cidade + "-" + option.uf }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="iss" label-for="iss" label="% Aliq. ISS">
              <money
                class="form-control"
                v-bind="configTaxa"
                v-model="municipioToAdd.aliquota_iss"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="retencaoIss"
              label-for="retencaoIss"
              label="% Retenção de ISS"
            >
              <money
                class="form-control"
                v-bind="configTaxa"
                v-model="municipioToAdd.retencao_iss"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
              openModal = false;
            }
          "
        >
          Cancelar
        </b-button>
        <b-button variant="primary" ref="sendToWpp" @click="addMunicipioISS">
          Adicionar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSelect from "vue-select";
import TableApp from "@/components/Table/Table3.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import _ from "lodash";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { mapActions } from "vuex";

import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-tributacao-create",
  components: {
    VueSelect,
    TableApp,
    TrashCanIcon,
    PlusIcon,
    Money,
  },
  data() {
    return {
      form: {},
      configTaxa: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 4,
        masked: false,
      },
      cstSelected: null,
      configMask: settingMoney,
      tabIndex: 0,
      render: 0,
      cstcofinsOptions: [],
      tributacaoTipos: [],
      tributacao: null,
      exigibilidade: null,
      exigibilidadeTipos: [],
      regras: [],
      municipioSelected: null,
      municipioToAdd: {
        aliquota_iss: null,
        retencao_iss: null,
      },
      municipiosOptions: [],
      fields: [
        {
          label: "Município",
          field: "cidade",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "% ISS",
          field: "aliquota_iss",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "% Retenção de ISS",
          field: "retencao_iss",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          width: "15%",
          slot: true,
        },
      ],
    };
  },

  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    setCst(value) {
      this.form.cst_cofins = value.codigo;
      this.cstSelected = value;
    },
    addMunicipioISS() {
      this.regras.push({
        cidade:
          this.municipioSelected.cidade + " - " + this.municipioSelected.uf,
        id_cidade: this.municipioSelected.id_cidade,
        aliquota_iss: this.municipioToAdd.aliquota_iss,
        retencao_iss: this.municipioToAdd.retencao_iss,
      });
      this.municipioSelected = null;
      this.municipioToAdd.aliquota_iss = null;
      this.municipioToAdd.retencao_iss = null;
      this.$bvModal.hide("modal-iss-cidade");
    },
    handleDeleteIss(data) {
      this.regras = this.regras.filter(
        (regra) => regra.id_cidade !== data.id_cidade
      );
    },
    onSearchMunicipio(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchMunicipio(loading, search, this);
      }
    },
    searchMunicipio: _.debounce(async (loading, search, vm) => {
      const response = await vm.$http.post("/tributacaoservico/get-cidade", {
        cidade: search,
      });
      vm.municipiosOptions.splice(
        0,
        vm.municipiosOptions.length,
        ...response.data
      );
      loading(false);
    }, 500),

    async getDataInit() {
      this.setLoading(true);
      const responseTributacao = await this.$http.post(
        "/tributacaoservico/get-tipos-tributacao"
      );
      const responseExigibilidadeTipos = await this.$http.post(
        "/tributacaoservico/get-exigibilidade-tributacao"
      );
      this.tributacaoTipos = responseTributacao.data;
      this.exigibilidadeTipos = responseExigibilidadeTipos.data;
      this.cstcofinsOptions = await this.getCSTCofinsByCodigo();

      if (!_.isEmpty(this.$route.params)) {
        const tributacaoData = this.$route.params.tributacaoData;
        this.form = { ...tributacaoData };
        this.form.retencao_cofins = this.numberToReal(
          this.form.retencao_cofins
        );
        this.form.retencao_cpp = this.numberToReal(this.form.retencao_cpp);
        this.form.retencao_csll = this.numberToReal(this.form.retencao_csll);
        this.form.retencao_irrf = this.numberToReal(this.form.retencao_irrf);
        this.form.retencao_inss = this.numberToReal(this.form.retencao_inss);
        this.form.retencao_pis = this.numberToReal(this.form.retencao_pis);
        this.regras = tributacaoData.tributacao_servico_cidade.map((item) => ({
          ...item,
          cidade: item.cidade.cidade + " - " + item.cidade.uf,
        }));
        this.tributacao = tributacaoData.tipoTributacao;
        this.exigibilidade = tributacaoData.exigibilidade;
        this.cstSelected = this.cstcofinsOptions.find(
          (option) => option.codigo == tributacaoData.cst_cofins
        );
        this.render++;
      }
      this.setLoading(false);
    },
    async onSubmit() {
      this.addLoadingToButton("submit_nova_tributacao");
      const dataToSave = {
        ...this.form,
        tipoTributacao: this.tributacao,
        exigibilidade: this.exigibilidade,
        regras: this.regras,
      };
      try {
        if (!_.isEmpty(this.$route.params)) {
          await this.$http.post("/tributacaoservico/update", dataToSave);
          this.$toast.success("Tributação atualizada com sucesso!");
        } else {
          await this.$http.post("/tributacaoservico/create", dataToSave);
          this.$toast.success("Tributação salva com sucesso!");
        }
        this.$router.push({ name: "tributacoesServicos" });
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_nova_tributacao");
    },
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
};
</script>
