<template>
  <b-tab
    title="Destinatario"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
    @click="() => (store.currentTab = 1)"
  >
    <b-row>
      <b-col sm="8">
        <b-form-group label="Cliente" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            :state="validateClienteState('nome')"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clientes"
            @search="onSearchCliente"
            :value="form.nome"
            :clearSearchOnSelect="true"
          >
            <template slot="no-options"> Informe o nome do cliente </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.nome }}</div>
                <span class="text-muted"
                  >CPF: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col sm="4" v-if="!form.estrangeiro">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="form.cnpj_cpf"
            :state="validateClienteState('cnpj_cpf')"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="form.estrangeiro">
      <b-col sm="5">
        <b-form-group label="Logradouro" label-for="logradouro_estrangeiro">
          <b-form-input
            id="logradouro_estrangeiro"
            name="logradouro_estrangeiro"
            v-model="form.logradouro_estrangeiro"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group label="Número" label-for="numero_estrangeiro">
          <b-form-input
            id="numero_estrangeiro"
            name="numero_estrangeiro"
            v-model="form.numero_estrangeiro"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Bairro" label-for="bairro_estrangeiro">
          <b-form-input
            id="bairro_estrangeiro"
            name="bairro_estrangeiro"
            v-model="form.bairro_estrangeiro"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="UF" label-for="uf_estrangeiro">
          <b-form-input
            id="uf_estrangeiro"
            name="uf_estrangeiro"
            v-model="form.uf_estrangeiro"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="País" label-for="pais_estrangeiro">
          <b-form-input
            id="pais_estrangeiro"
            name="pais_estrangeiro"
            v-model="form.pais_estrangeiro"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="form.estrangeiro && store.formNatureza.cfop.substring(0, 1) === '7'"
    >
      <b-col sm="4">
        <b-form-group label="Local do embarque" label-for="local_embarque">
          <b-form-input
            id="local_embarque"
            name="local_embarque"
            v-model="form.local_embarque"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="1">
        <b-form-group label="UF" label-for="uf_embarque">
          <b-form-input
            id="uf_embarque"
            name="uf_embarque"
            v-model="form.uf_embarque"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Local do despacho" label-for="local_despacho">
          <b-form-input
            id="local_despacho"
            name="local_despacho"
            v-model="form.local_despacho"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="form?.cnpj_cpf?.length > 14">
      <b-col sm="6">
        <b-form-group
          id="identificador_ie"
          label-for="identificador_ie"
          label="Indicador de IE"
        >
          <select
            name="identificador_ie"
            id="identificador_ie"
            v-model="form.identificador_ie"
            class="form-control"
            @change="setIe()"
          >
            <option
              v-for="(i, index) in identificadores_ie"
              :value="i.id"
              :key="'identificador' + index"
            >
              {{ i.descricao }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="IE" label-for="ie_rg">
          <b-form-input
            :key="keyToReRender"
            id="ie_rg"
            name="ie_rg"
            v-mask="['#################']"
            v-model="form.ie_rg"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!form.estrangeiro">
      <b-col sm="10">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <VueSelect
            ref="endereco"
            :filterable="false"
            @input="setEndereco"
            :options="enderecos"
            :class="{ hasErrors: enderecoError, inputFilled: enderecoFilled }"
            @search="onSearchEndereco"
            :value="formatEndereco(form.endereco)"
            :state="validateClienteState('endereco')"
          >
            <template slot="no-options"> Informe o Endereço </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{
                  `${option.cep ?? ""}, ${option.logradouro ?? ""}, ${
                    option.numero ?? ""
                  }, ${option.complemento ?? ""},
                  ${option.bairro ?? ""} - ${option?.cidade?.cidade ?? ""} / ${
                    option?.cidade?.uf ?? ""
                  }`
                }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!form.estrangeiro">
      <b-col sm="5">
        <b-form-group :key="keyToReRender" label="E-mail" label-for="email">
          <VueSelect
            ref="buscarNatureza"
            :filterable="false"
            @input="setEmail"
            :options="emails"
            :class="{ hasErrors: emailError, inputFilled: emailFilled }"
            @search="onSearchEmail"
            :value="form.email"
          >
            <template slot="no-options"> Informe o E-mail </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.email }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.email }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col sm="5">
        <b-form-group :key="keyToReRender" label="Telefone" label-for="fone">
          <VueSelect
            ref="fone"
            :filterable="false"
            :options="fones"
            @input="setFone"
            v-model="form.fone"
          >
            <template slot="no-options"> Informe o Telefone </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.fone }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.fone }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Contato" label-for="contato">
          <b-form-input
            id="contato"
            name="contato"
            v-model="form.contato"
            :state="validateClienteState('contato')"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="2" style="margin-top: 35px" v-if="form.tipo_atacado">
        <span class="label label-lg label-inline label-light-warning"
          >Tipo Atacado</span
        >
      </b-col>
    </b-row>
    <b-row v-if="limiteCreditoAtigindo">
      <b-col sm="12">
        <span class="label label-lg label-inline label-light-danger"
          >Limite de crédito atingido</span
        >
        -
        <span
          ><strong
            >Limite: R$ {{ numberToReal(valorLimiteCredito, 2) }}</strong
          ></span
        >
        /
        <span
          v-b-tooltip.righttop="
            'O limite de crédito considera contas a receber na situação (Em aberto)'
          "
          ><strong
            >Atingido: R$
            {{ numberToReal(valorLimiteCreditoAtingido, 2) }}</strong
          ></span
        >
      </b-col>
    </b-row>
    <b-row v-if="form.produtor_rural && store.tpNF == 0">
      <b-col sm="4">
        <b-form-group label="Número" label-for="numeroNF">
          <b-form-input
            id="numeroNF"
            name="numeroNF"
            v-model="rural.numeroNF"
            type="number"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Série" label-for="serie">
          <b-form-input
            id="serie"
            name="serie"
            v-model="rural.serie"
            type="number"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Selecione o modelo" label-for="modelo">
          <select
            name="modelo"
            id="modelo"
            v-model="rural.modelo"
            class="form-control"
          >
            <option
              v-for="(m, index) in modelosProdutor"
              :value="m.modelo"
              :key="'id' + index"
            >
              {{ m.modelo }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Mês" label-for="mes">
          <b-form-input
            id="mes"
            name="mes"
            v-model="rural.mes"
            v-mask="'##'"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="Ano" label-for="ano">
          <b-form-input
            id="ano"
            name="ano"
            v-model="rural.ano"
            v-mask="'####'"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="10" v-if="rural.modelo == 55">
        <b-form-group label="Chave de Acesso da NFe" label-for="chave">
          <b-form-input
            id="chave"
            name="chave"
            v-model="rural.chave"
            type="number"
            :formatter="formatKeyProdutor"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="listaPreco">
      <b-col>
        <p class="text-muted">
          Lista de preço aplicada:
          <span class="font-weight-bold text-primary">{{
            listaPreco.descricao
          }}</span>
        </p>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import ClienteData, { ClienteDataRequired } from "../ClienteData";
import RuralData, { RuralDataRequired } from "../RuralData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeNfe";

export default {
  name: "app-nfe-form-clienteTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      listaPreco: null,
      form: {},
      clientes: [],
      emails: [],
      cliente: null,
      ieOptions: ["Contribuinte", "Isento", "Não contribuinte"],
      identificadores_ie: [
        {
          id: 1,
          descricao: "Contribuinte",
        },
        {
          id: 2,
          descricao: "Isento",
        },
        {
          id: 9,
          descricao: "Não Contribuinte",
        },
      ],
      enderecos: [],
      keyToReRender: 0,
      fones: null,
      isCnpj: false,
      store,
      clienteError: false,
      enderecoError: false,
      emailError: false,
      emailFilled: false,
      clienteFilled: false,
      enderecoFilled: false,
      rural: {},
      modelosProdutor: [
        {
          id: 1,
          modelo: "01",
        },
        {
          id: 2,
          modelo: "04",
        },
        {
          id: 3,
          modelo: "55",
        },
      ],
      limiteCreditoAtigindo: false,
      valorLimiteCredito: 0,
      valorLimiteCreditoAtingido: 0,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  validations: {
    form: ClienteDataRequired,
    rural: RuralDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSearchEmail() {},
    setEmail(newEmail) {
      this.form.email = newEmail;
      this.keyToReRender++;
      this.emailFilled = true;
    },
    async calcTotais() {
      const dataSendToApi = this.formatDataToSendToApi(
        this.store.formNatureza,
        this.store.formProduto,
        this.store.formCliente,
        this.store.formFaturamento.pagamentos.length === 0
          ? null
          : this.store.formFaturamento,
        this.store.formImpostos
      );
      const { dados } = await this.getTotaisNfe(dataSendToApi);

      this.store.formImpostos = {
        ...this.store.formImpostos,
        ...dados.total,
      };
    },
    setFone(newFone) {
      this.form.fone = newFone;
      this.keyToReRender++;
    },
    searchEndereco: _.debounce(async (loading, search, vm) => {
      vm.enderecos = vm.enderecos.filter((endereco) =>
        endereco.includes(search)
      );
      loading(false);
    }, 350),
    onSearchEndereco(search, loading) {
      if (search.length) {
        loading(true);
        this.searcEndereco(loading, search, this);
      }
    },
    setEndereco(endereco) {
      this.form.endereco = endereco;
      this.keyToReRender++;
      this.enderecoFilled = true;
    },
    setIe() {
      this.form.identificador_ie = this.form.identificador_ie;
      if (this.form.identificador_ie == 2) {
        this.form.ie_rg = "Isento";
      }
    },
    validateClienteState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSearchIndicador(search, loading) {
      loading(true);
      this.searchIndicador(loading, search, this);
    },
    searchIndicador: _.debounce((loading, search, vm) => {
      if (search === "" || search === null) {
        vm.identificadores_ie = [
          {
            id: 1,
            descricao: "Contribuinte",
          },
          {
            id: 2,
            descricao: "Isento",
          },
          {
            id: 3,
            descricao: "Não Contribuinte",
          },
        ];
        return loading(false);
      }
      vm.identificadores_ie = vm.identificadores_ie.filter((option) =>
        option.toLowerCase().includes(search.toLowerCase())
      );
      return loading(false);
    }, 350),
    onSearchCliente(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = [];
      await vm.$nextTick();
      const data = await vm.getClientes(search);
      vm.clientes = _.cloneDeep(data);
      loading(false);
    }, 500),
    setCliente(cliente) {
      if (!cliente) return;

      this.limiteCreditoAtigindo = false;
      this.valorLimiteCredito = 0;
      this.limiteCreditoAtigindo = 0;
      this.listaPreco = null;

      if (cliente?.lista_preco) {
        this.listaPreco = cliente.lista_preco;
      }

      if (cliente.estrangeiro) {
        this.form = cliente;
        this.form.cnpj_cpf = 1;
        this.form.contato = cliente.nome;
        this.form.endereco = cliente.logradouro_estrangeiro;
        this.clienteFilled = true;
        this.emailFilled = true;
        this.enderecoFilled = true;
        return;
      }

      if (
        Object.keys(this.$route.params).length === 0 ||
        this.$route.params.nfeData.buyer.id_pessoa != cliente.id_pessoa
      ) {
        if (cliente.limite_credito > 0) {
          if (
            cliente.limite_de_credito &&
            parseFloat(cliente.limite_credito) <
              parseFloat(cliente.limite_de_credito.valor_total)
          ) {
            this.$toast.warning(
              "Limite de crédito do cliente atingido, verifique!"
            );
            this.getRequestAdmin(() => {
              this.setClienteInadimplente(cliente);
            });
            return;
          }
        }
      }

      this.form = cliente;
      this.enderecos = cliente.enderecos;
      this.form.endereco = this.enderecos[0];
      this.fones = cliente.fones;
      this.form.fone = this.form.fones[0] ?? null;
      this.emails = cliente?.emails ?? null;
      this.form.email = this.emails[0];
      this.isCnpj = this.form.cnpj_cpf.length > 11;
      this.form.contato = cliente.nome.split(" ")[0] ?? null;
      this.clienteFilled = true;
      this.emailFilled = true;
      this.enderecoFilled = true;
    },
    setClienteInadimplente(cliente) {
      this.limiteCreditoAtigindo = true;
      this.valorLimiteCredito = cliente.limite_credito;
      this.valorLimiteCreditoAtingido = cliente.limite_de_credito.valor_total;
      this.form = cliente;
      this.enderecos = cliente.enderecos;
      this.form.endereco = this.enderecos[0];
      this.fones = cliente.fones;
      this.form.fone = this.form.fones[0] ?? null;
      this.emails = cliente?.emails ?? null;
      this.form.email = this.emails[0];
      this.isCnpj = this.form.cnpj_cpf.length > 11;
      this.form.contato = cliente.nome.split(" ")[0] ?? null;
      this.clienteFilled = true;
      this.emailFilled = true;
      this.enderecoFilled = true;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.complemento ?? ""}, ${
        endereco.bairro ?? ""
      } - ${endereco?.cidade?.cidade ?? ""} / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    formatKeyProdutor(e) {
      return String(e).substring(0, 44);
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      this.form = new ClienteData({});
      this.rural = this.$route.params.nfeData.rural ?? null;
      const id_pessoa = this.$route.params.nfeData.buyer.id_pessoa;
      this.$http
        .post("/pessoa/find", {
          id_pessoa,
        })
        .then((response) => {
          this.setCliente(response.data);
        })
        .catch((error) =>
          this.$toast.error(`Erro ao buscar cliente:\n${error}`)
        );
    } else {
      this.form = new ClienteData({});
      this.rural = new RuralData({});
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },

    formatEnderecos() {
      const formatData = this.enderecos
        ? this.enderecos.map(
            (endereco, index) =>
              `${index === 0 ? "Principal - " : ""}${endereco.cep ?? ""}, ${
                endereco.logradouro ?? ""
              }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
                endereco?.cidade?.cidade ?? ""
              } / ${endereco?.cidade?.uf ?? ""}`
          )
        : null;
      return formatData;
    },
    isTheCurrentTab() {
      return store.currentTab === 1;
    },
  },
  watch: {
    "store.formCliente"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.form = newValue;
        this.fones = this.form.fones;
        this.enderecos = this.form.enderecos;
        this.emails = this.form.emails;
        this.keyToReRender++;
        if (!this.form.contato) this.form.contato = newValue.contactPerson;
      }
    },
    "store.formCliente.fone"(newValue, oldValue) {
      this.keyToReRender++;

      if (newValue !== oldValue) {
        this.keyToReRender++;
      }
    },
    form(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.store.formCliente = newValue;
    },
    "store.formRural"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.rural = newValue;
        this.store.formRural = newValue;
      }
    },
    rural(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.store.formRural = newValue;
      }
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 2 && oldValue < newValue) {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          if (this.$v.form.$anyError) {
            this.store.currentTab = oldValue;
            if (this.$v.form.nome.$anyError) {
              this.$toast.error("Informe o cliente!");
              this.clienteError = true;
            }
            if (this.$v.form.endereco.$anyError) {
              this.$toast.error("Informe o endereco do cliente!");
              this.enderecoError = true;
            }
          }
          return;
        } else {
          this.store.formNatureza.cfop === "5929" && this.calcTotais();
        }
      }
    },
  },
};
</script>
