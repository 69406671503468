/* eslint-disable prettier/prettier */
import JwtService from "@/core/services/jwt.service";
import { http } from "@/configurations/axios";
import { priceOptions, quantityOptions } from '@/modules/Application'
import { adminPermissionStore } from "@/adminPermissionStore";
import { store as StoreVenda } from '@/modules/Vendas/storeVenda.js'
import { store as storeNfe } from '@/modules/NFe/storeNfe.js'
import { store as storePedidos } from '@/modules/Pedidos/storePedidos.js'
import { store as StoreOrcamento } from '@/modules/Orcamento/storeOrcamento.js'
import { store as StoreOS } from '@/modules/OS/storeOS.js'
import { store as StoreServicos } from '@/modules/Servicos/storeServicos.js'

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const VERIFY_ASSINATURA = "verifyAssinatura";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_ASSINATURA = "setAssinatura";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  itsblocked: false,
  isNuts: false,
  isRevenda: false,
  idRevenda: null,
  dadosRevenda: {},
  nutsDigital: null,
  licenca: null,
  vendedor: {},
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  itsblocked(state) {
    return state.itsblocked;
  },
  isNuts(state) {
    return state.isNuts;
  },
  isRevenda(state) {
    return state.isRevenda;
  },
  idRevenda(state) {
    return state.idRevenda;
  },
  dadosRevenda(state) {
    return state.dadosRevenda;
  },
  nutsDigital(state) {
    return state.nutsDigital;
  },
  licenca(state) {
    return state.licenca;
  },
  vendedor(state) {
    return state.vendedor;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      http
        .post("/autenticacao", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      http
        .post("/autenticacao/logout")
        .then(async ({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      http
        .post("/empresa/create", credentials)
        .then(async ({ data }) => {
          await context.dispatch(LOGIN, {
            email: credentials.email,
            password: credentials.password
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      http
        .get("/autenticacao/dados")
        .then(({ data }) => {
          quantityOptions.precision = data?.configuracoes?.casas_decimal_quantidade;
          priceOptions.precision = data?.configuracoes?.casas_decimal_preco;
          StoreVenda.aberturaCaixa = data?.configuracoes?.abertura_caixa ?? false;
          adminPermissionStore.requestPermission = data?.configuracoes?.solicita_senha_adm ?? false;
          StoreVenda.verificaEstoque = data?.configuracoes?.verifica_estoque ?? false;
          storeNfe.verificaEstoque = data?.configuracoes?.verifica_estoque ?? false;
          storePedidos.verificaEstoque = data?.configuracoes?.verifica_estoque ?? false;
          StoreVenda.naoGeraImpressao = data?.configuracoes?.nao_gerar_impressao ?? false;
          StoreVenda.vendedorObrigatorio = data?.configuracoes?.vendedor_obrigatorio ?? false;
          storePedidos.pafNFCe = data?.paf_nfce ?? false;
          StoreVenda.pafNFCe = data?.paf_nfce ?? false;
          StoreOrcamento.pafNFCe = data?.paf_nfce ?? false;
          StoreOS.pafNFCe = data?.paf_nfce ?? false;
          StoreServicos.informacoesComplementares = data?.configuracoes.informacao_nfse ?? null;
          StoreOS.trabalharHorasOs = data?.configuracoes?.trabalhar_horas_os ?? false;
          StoreOS.trabalharNumeroInternoOs = data?.configuracoes?.trabalhar_numero_interno_os ?? false;
          if (data?.admin) adminPermissionStore.requestPermission = false;
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_ASSINATURA](context) {
    if (JwtService.getToken()) {
      http
        .post("/empresa/assinatura")
        .then(({ data }) => {
          if (data) {
            context.commit(SET_ASSINATURA, data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.errors = {};
    state.user = user;
    state.itsblocked = user.empresa.assinatura.bloqueado;
    if (user.token) {
      JwtService.saveToken(state.user.token);
    }
    state.isNuts = user.empresa.isNuts;
    state.isRevenda = user.empresa.isRevenda;
    if (user.empresa.isRevenda) {
      JwtService.saveParceiro(user.empresa.isRevenda);
    }
    state.idRevenda = user.empresa.dados_revenda.id_revenda;
    state.dadosRevenda = user.empresa.dados_revenda;
    state.nutsDigital = user.nuts_digital;
    state.licenca = user;
    state.vendedor = user;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyTokenCliente();
    JwtService.destroyParceiro();;
    state.itsblocked = false;
    state.isNuts = false;
    state.isRevenda = false;
    state.idRevenda = null;
    state.dados_revenda = {};
    state.nutsDigital = null;
    state.licenca = null;
    state.vendedor = {};
  },
  [SET_ASSINATURA](state, data) {
    state.itsblocked = data.bloqueado;
    state.isNuts = data.nuts;
    state.isRevenda = data.revenda;
    state.idRevenda = data.id_revenda;
    state.dadosRevenda.id_revenda = data.id_revenda;
    state.dadosRevenda.nome_revenda = data.nome_revenda;
    state.dadosRevenda.fone_revenda = data.fone_revenda;
    state.nutsDigital = data.nuts_digital;
    state.licenca = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
