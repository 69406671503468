import { required } from "vuelidate/lib/validators";

export default class GeraisData {
  cliente = null;
  tecnico = null;
  dataEntrada = null;
  dataSaida = null;
  dataPrevisao = null;
  proxRevisao = null;
  garantia = null;
  solicitante = null;
  horaAgendamento = null;
  local_atendimento = null;
  numero_interno = null;
  constructor(
    cliente = null,
    tecnico = null,
    dataEntrada = null,
    dataSaida = null,
    dataPrevisao = null,
    proxRevisao = null,
    garantia = null,
    solicitante = null,
    horaAgendamento = null,
    local_atendimento = null,
    numero_interno = null
  ) {
    this.cliente = cliente;
    this.dataEntrada = dataEntrada;
    this.dataSaida = dataSaida;
    this.dataPrevisao = dataPrevisao;
    this.tecnico = tecnico;
    this.proxRevisao = proxRevisao;
    this.garantia = garantia;
    this.solicitante = solicitante;
    this.horaAgendamento = horaAgendamento;
    this.local_atendimento = local_atendimento;
    this.numero_interno = numero_interno;
  }
}

export const NFeDataRequired = {
  tecnico: {
    required,
  },
  cliente: {
    required,
  },
};
