<template>
  <div>
    <b-modal
      id="modal-excluir-movimento"
      title="Confirmar Exclusão"
      ok-title="Excluir"
      ok-variant="danger"
      cancel-title="Cancelar"
      @ok="excluirMovimento"
      :ok-disabled="isDeleting"
    >
      <p>Tem certeza que deseja excluir este lançamento?</p>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="secondary" @click="cancel" :disabled="isDeleting">
          Cancelar
        </b-button>

        <b-button variant="danger" @click="ok" :disabled="isDeleting">
          <b-spinner small v-if="isDeleting"></b-spinner>
          <span v-else>Excluir</span>
        </b-button>
      </template>
    </b-modal>
    <b-sidebar
      id="sidebar-transferencia"
      backdrop-variant="dark"
      backdrop
      right
      shadow
    >
      <div class="px-2 py-2">
        <div style="width: 100%" class="p-2">
          <h4 class="text-dark">Transferência entre contas</h4>
          <p>A transferência será realizada somente utilizando o saldo.</p>
          <b-col md="12">
            <b-form-group
              id="descricao"
              label-for="descricao"
              label="Descrição"
            >
              <b-form-input v-model="transferencia.descricao"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group id="banco" label-for="banco" label="Conta Destino:">
              <b-form-select v-model="transferencia.banco">
                <b-form-select-option
                  v-for="b in bancos"
                  :key="b.id_banco + 'bancos'"
                  :value="b"
                >
                  {{ b.nome }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              id="data_transferencia"
              label-for="data_transferencia"
              label="Data Transferência:"
            >
              <b-form-input
                type="date"
                id="data_transferencia"
                placeholder=" "
                locale="pt-br"
                v-model="transferencia.data_transferencia"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group id="valor" label-for="valor" label="Valor R$:">
              <money
                class="form-control"
                v-model="transferencia.valor_tranferencia"
                v-bind="configMoney"
              >
              </money>
            </b-form-group>
          </b-col>
        </div>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <button
            style="width: 100%"
            ref="salvarTranferencia"
            class="btn btn-primary btn-hover-dark font-weight-bold"
            @click="salvarTransferencia"
          >
            Realizar transferência
          </button>
        </div>
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-movimento-fields"
      backdrop-variant="dark"
      backdrop
      right
      shadow
    >
      <div class="px-2 py-2">
        <div style="width: 100%" class="p-2">
          <h4 class="text-dark">Inserir novo movimento:</h4>
          <b-col md="12">
            <b-form-group
              id="descricao"
              label-for="descricao"
              label="Descrição"
            >
              <b-form-input v-model="lancamento.descricao"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              id="data_movimento"
              label-for="data_movimento"
              label="Data Movimento:"
            >
              <b-form-input
                type="date"
                id="data_movimento"
                placeholder=" "
                locale="pt-br"
                v-model="lancamento.data_movimento"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group id="valor" label-for="valor" label="Valor R$:">
              <money
                class="form-control"
                v-model="lancamento.valor"
                v-bind="configMoney"
              >
              </money>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Tipo movimento:" label-for="tipo">
              <b-form-select v-model="lancamento.tipo">
                <b-form-select-option
                  v-for="t in tiposMovimento"
                  :key="t.id + 'tipo'"
                  :value="t.id"
                >
                  {{ t.descricao }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <button
            style="width: 100%"
            ref="salvarMovimento"
            class="btn btn-primary btn-hover-dark font-weight-bold"
            @click="salvarMovimento"
          >
            Realizar Lançamento
          </button>
        </div>
      </template>
    </b-sidebar>
    <b-card
      :title="titulo_card"
      tag="article"
      class="mb-2 border border-primary"
    >
      <div class="col-md-2">
        <b-form @submit.prevent="filterMovimentacoes">
          <b-form-group label="Período:" label-for="periodoSelect">
            <b-form-select
              id="periodoSelect"
              v-model="selectedPeriodo"
              :options="periodos"
            ></b-form-select>
          </b-form-group>

          <div v-if="selectedPeriodo === 'personalizado'">
            <b-form-group label="Data Inicial:" label-for="dataInicial">
              <b-form-datepicker
                id="dataInicial"
                v-model="data_inicial"
                placeholder="Selecione uma data"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Data Final:" label-for="dataFinal">
              <b-form-datepicker
                id="dataFinal"
                v-model="data_final"
                placeholder="Selecione uma data"
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <b-button type="button" variant="primary" @click="applyPeriod"
            >Aplicar</b-button
          >
          <b-button
            type="button"
            variant="secondary"
            class="ml-2"
            @click="goBack"
          >
            Voltar
          </b-button>
        </b-form>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-2">
        <div></div>
        <b-button
          class="border border-muted"
          v-b-toggle.sidebar-movimento-fields
          variant="primary"
          v-b-tooltip.hover.right="'Novo lançamento'"
          style="position: absolute; right: 10px; top: 10px"
        >
          <Plus :size="18" />
        </b-button>
        <b-button
          class="ml-2 border border-muted"
          v-b-toggle.sidebar-transferencia
          variant="primary"
          v-b-tooltip.hover.right="'Transferência entre contas'"
          style="position: absolute; right: 60px; top: 10px"
        >
          <SwapHorizontal :size="18" />
        </b-button>
        <b-button variant="primary" @click="printData"
          ><IconPrint :size="18"
        /></b-button>
      </div>

      <div v-if="isLoading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
        <p>Carregando...</p>
      </div>

      <b-table
        v-if="!isLoading && tableMovimentacoes.length > 0"
        striped
        small
        hover
        responsive="sm"
        :fields="fields"
        :items="tableMovimentacoes"
      >
        <template v-slot:cell(descricao)="data">
          <div
            v-if="data.item.isSaldoAnterior"
            class="font-weight-bold text-right"
          >
            Saldo em {{ formatDate(data.item.data_saldo_dia_anterior) }}
          </div>
          <div v-else>
            {{ data.item.descricao }}
          </div>
        </template>

        <template v-slot:cell(valor_movimento)="data">
          <div v-if="data.item.isSaldoAnterior" class="font-weight-bold">
            {{ numberToReal(data.item.valor_movimento) }}
          </div>
          <div v-else>
            <a
              href="#"
              @click.prevent="confirmarExclusao(data.item)"
              :style="{
                color: data.item.tipo_movimento === 1 ? 'green' : 'red',
                textDecoration: 'underline',
                cursor: 'pointer',
              }"
            >
              {{ numberToReal(data.item.valor_movimento) }}
            </a>
          </div>
        </template>

        <template v-slot:cell(data_movimento)="data">
          <div v-if="data.item.isSaldoAnterior"></div>
          <div v-else>
            {{ formatDate(data.item.data_movimento) }}
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import moment from "moment";
import IconPrint from "vue-material-design-icons/Printer";
import Plus from "vue-material-design-icons/Plus.vue";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import SwapHorizontal from "vue-material-design-icons/SwapHorizontal.vue";

export default {
  name: "app-movimentacao-banco",
  mixins: [SistemaMixin],
  data() {
    return {
      banco: null,
      titulo: null,
      movimentacoes: [],
      data_inicial: null,
      data_final: null,
      titulo_card: null,
      fields: [
        { key: "data_movimento", label: "Data", sortable: true },
        { key: "descricao", label: "Descrição", sortable: false },
        { key: "valor_movimento", label: "Valor", sortable: false },
      ],
      isLoading: true,
      selectedPeriodo: null,
      periodos: [
        { value: null, text: "Selecione um período" },
        { value: "hoje", text: "Hoje" },
        { value: "ontem", text: "Ontem" },
        { value: "semana", text: "Essa Semana" },
        { value: "semana_passada", text: "Semana Passada" },
        { value: "mes", text: "Esse Mês" },
        { value: "personalizado", text: "Período Personalizado" },
      ],
      lancamento: {},
      configMoney: settingMoney,
      tiposMovimento: [
        { id: 2, descricao: "Saída" },
        { id: 1, descricao: "Entrada" },
      ],
      lancamentoSelecionado: null,
      bancos: [],
      transferencia: {},
      isDeleting: false,
    };
  },
  components: {
    IconPrint,
    Plus,
    Money,
    SwapHorizontal,
  },
  methods: {
    confirmarExclusao(lancamento) {
      this.lancamentoSelecionado = lancamento;
      this.$bvModal.show("modal-excluir-movimento");
    },

    async excluirMovimento() {
      if (!this.lancamentoSelecionado) return;

      this.isDeleting = true; // Ativa o loading

      try {
        await this.$http.post("/bancos/deletar-movimentacao", {
          id_movimento: this.lancamentoSelecionado.id_movimento,
          tipo: this.lancamentoSelecionado.tipo_movimento,
          valor: this.lancamentoSelecionado.valor_movimento,
          id_banco: this.banco.id_banco,
        });

        this.$toast.success("Movimentação excluída com sucesso!");
        this.applyPeriod(); // Atualizar listagem
      } catch (error) {
        this.$toast.error("Erro ao excluir movimentação!");
      }

      this.isDeleting = false; // Desativa o loading
      this.$bvModal.hide("modal-excluir-movimento"); // Fecha o modal após a exclusão
    },

    onPeriodChange() {
      if (this.selectedPeriodo !== "personalizado") {
        this.data_inicial = null;
        this.data_final = null;
      }
    },
    async salvarTransferencia() {
      if (!this.transferencia.banco) {
        this.$toast.warning("Seleciona a conta para transferência!");
        return;
      }

      if (this.transferencia.valor_tranferencia <= 0) {
        this.$toast.warning("Informe o valor da transferência!");
        return;
      }

      this.transferencia.banco_origem = this.banco.id_banco;

      this.addLoadingToButton("salvarTranferencia");
      try {
        await this.$http.post("/bancos/transferencia", this.transferencia);
        this.$toast.success("Transferência realizada com sucesso!");
        this.transferencia = {};
        this.applyPeriod();
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("salvarTranferencia");
      this.$root.$emit("bv::toggle::collapse", "sidebar-transferencia");
    },
    async salvarMovimento() {
      if (!this.banco) {
        this.$toast.warning("Nenhum banco selecionado!");
        return;
      }

      if (this.lancamento.valor <= 0) {
        this.$toast.warning("Informe o valor do movimento!");
        return;
      }

      if (!this.lancamento.tipo) {
        this.$toast.warning("Selecione o tipo do movimento!");
        return;
      }

      this.lancamento.id_banco = this.banco.id_banco;

      this.addLoadingToButton("salvarMovimento");
      try {
        await this.$http.post("/bancos/movimentacao-create", this.lancamento);
        this.$toast.success("Lançamento realizado com sucesso!");
        this.applyPeriod();
        this.lancamento = {};
      } catch (error) {
        console.log(error);
      }
      this.lancamento = {};
      this.removeLoadingToButton("salvarMovimento");
      this.$root.$emit("bv::toggle::collapse", "sidebar-movimento-fields");
    },
    applyPeriod() {
      if (this.selectedPeriodo) {
        const currentDate = moment();

        switch (this.selectedPeriodo) {
          case "hoje":
            this.data_inicial = currentDate
              .clone()
              .startOf("day")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("day")
              .format("YYYY-MM-DD");
            break;
          case "ontem":
            this.data_inicial = currentDate
              .clone()
              .subtract(1, "days")
              .startOf("day")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .subtract(1, "days")
              .endOf("day")
              .format("YYYY-MM-DD");
            break;
          case "semana":
            this.data_inicial = currentDate
              .clone()
              .startOf("week")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("week")
              .format("YYYY-MM-DD");
            break;
          case "semana_passada":
            this.data_inicial = currentDate
              .clone()
              .subtract(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .subtract(1, "week")
              .endOf("week")
              .format("YYYY-MM-DD");
            break;
          case "mes":
            this.data_inicial = currentDate
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");
            break;
        }
      }

      this.titulo_card =
        this.banco.nome +
        ": movimentos do período: " +
        this.formatDate(this.data_inicial) +
        " até " +
        this.formatDate(this.data_final);

      this.filterMovimentacoes();
    },

    async getBanco(id) {
      try {
        const { data } = await this.$http.post("/bancos/find", {
          id_banco: id,
        });

        this.banco = data;
        this.titulo =
          "Movimentação bancária: " +
          data.nome +
          " " +
          data.agencia +
          "/" +
          data.conta;
      } catch (error) {
        return null;
      }
    },
    async filterMovimentacoes() {
      this.isLoading = true;
      const { data } = await this.$http.post("/bancos/movimentacoes", {
        id_banco: this.$route.params.id_banco,
        data_inicial: this.data_inicial,
        data_final: this.data_final,
      });

      this.movimentacoes = data;
      this.isLoading = false;
    },
    printData() {
      this.$router.push({
        name: "visualizarImpressaoBranco",
        params: {
          data: this.movimentacoes,
          labels: this.fields,
          title: this.titulo_card,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
    tableMovimentacoes() {
      const movimentacoes = this.movimentacoes;
      const tableData = [];
      let lastDate = null;
      let saldoTotal = 0;

      movimentacoes.forEach((mov, index) => {
        if (lastDate !== mov.data_movimento) {
          tableData.push({
            descricao: "",
            valor_movimento: mov.saldo_dia_anterior,
            data_movimento: "",
            tipo_movimento: null,
            data_saldo_dia_anterior: mov.data_saldo_dia_anterior,
            isSaldoAnterior: true,
          });
        }

        tableData.push({
          ...mov,
          saldo_dia_anterior: null,
          data_saldo_dia_anterior: null,
        });

        saldoTotal +=
          mov.tipo_movimento === 1
            ? parseFloat(mov.valor_movimento)
            : -parseFloat(mov.valor_movimento);

        lastDate = mov.data_movimento;
      });

      if (movimentacoes.length > 0) {
        const lastMov = movimentacoes[movimentacoes.length - 1];

        tableData.push({
          descricao: "Saldo Final",
          valor_movimento: saldoTotal,
          data_movimento: "",
          tipo_movimento: null,
          data_saldo_dia_anterior: lastMov.data_movimento,
          isSaldoAnterior: true,
        });
      }

      return tableData;
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    const currentDate = moment();
    const monthStart = currentDate
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const monthEnd = currentDate.clone().endOf("month").format("YYYY-MM-DD");

    this.data_inicial = monthStart;
    this.data_final = monthEnd;

    await this.getBanco(this.$route.params.id_banco);
    this.titulo_card =
      this.banco.nome +
      ": movimentos do período: " +
      currentDate.clone().startOf("month").format("DD/MM/YYYY") +
      " até " +
      currentDate.clone().endOf("month").format("DD/MM/YYYY");

    const { data } = await this.$http.post("/bancos/movimentacoes", {
      id_banco: this.$route.params.id_banco,
      data_inicial: this.data_inicial,
      data_final: this.data_final,
    });

    this.movimentacoes = data;
    this.isLoading = false;

    this.bancos = await this.getBancosByEmpresa();
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>
